import Lottie from 'lottie-react';
import animationData from "../../assets/animation/circlesData.json"

function AnimationCircleComponent() {
    return (
        <div className="w-full">
            <Lottie
                animationData={animationData}
                autoPlay={true}
            />
        </div>
    );
}

export default AnimationCircleComponent;