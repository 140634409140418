import DiagramaComponent from "../../widgets/Diagrama/DiagramaComponent";
import InfoComponent from "../../widgets/Info/InfoComponent";
import LandComponent from "../../widgets/Land/LandComponent";

function HomePage() {
    return (
        <div className="flex flex-col w-full">
            <LandComponent/>
            <DiagramaComponent/>
            <InfoComponent/>
        </div>
    );
}

export default HomePage;