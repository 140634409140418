import blinkText from "../../assets/img/blink-text.svg"
import xLogo from "../../assets/img/x_logo.svg"
import dsLogo from "../../assets/img/ds_logo.svg"
import gitLogo from "../../assets/img/git_logo.svg"
import { useContext } from "react"
import { AppContext } from "../../app/app.context"
import { cn } from "../../app/utils/cn"
import AnimationHoleComponent from "../Animation/AnimationHoleComponent"
import AnimationCircleComponent from "../Animation/AnimationCircleComponent"

function MobileLandComponent() {
    const context = useContext(AppContext)

    return (
      <div className="w-full flex flex-col gap-8 mb-16 mt-10">
        <div className="relative w-[350%]">
            <div className="absolute z-20 translate-x-[-30%]">
                <AnimationCircleComponent/>
            </div>
        </div>
        <div className="w-full z-30">
            <img src={blinkText} alt="" />
        </div>
        <div className="w-full text-left text-xs text-grey flex justify-end z-30">
            <div className="w-fit">
                Leading DEX on Blast. Providing <br/>advanced economy for liquidity pool<br/> providers, stakers and users.
            </div>
        </div>
        <div className="w-full z-30">
            <AnimationHoleComponent/>
        </div>
        <div className="w-full flex justify-between gap-4 font-variable text-base z-40">
            <a href="https://app.blinkswap.xyz/swap" className="w-full border-white border flex justify-center items-center uppercase py-3 text-white hover:bg-white hover:text-black duration-200">
                Launch App
            </a>
            <a href="https://app.blinkswap.xyz/quests" className="w-full border-white border flex justify-center items-center uppercase py-3 text-white hover:bg-white hover:text-black duration-200">
                Blink Points
            </a>
        </div>
      </div>
    );
}

export default MobileLandComponent;