import xLogo from "../../assets/img/x_logo.svg"
import dsLogo from "../../assets/img/ds_logo.svg"
import gitLogo from "../../assets/img/git_logo.svg"
import blinkLogo from "../../assets/img/blonk-logo.svg"
import { useContext } from "react"
import { AppContext } from "../../app/app.context"
import MobileFooterComponent from "./MobileFooterComponent"

function scrollToTop(){
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    })
}

function FooterComponent() {
    
    const context = useContext(AppContext)

    if(context.width < 550){
        return(
            <MobileFooterComponent/>
        )
    }

    return (
        <div className="w-full flex justify-between items-center mb-5">
            <div className="w-full">
                <img src={blinkLogo} alt="" className="size-16 mr-auto cursor-pointer" onClick={scrollToTop}/>
            </div>
            <div className="w-full flex items-center justify-center">
                <div className="flex gap-9 h-1/2 items-center">
                    <a href="https://twitter.com/blinkswap" target="_blank"><img src={xLogo} alt="" className="cursor-pointer size-5"/></a>
                    <a href="https://discord.gg/blinkswap" target="_blank"><img src={dsLogo} alt="" className="cursor-pointer size-5"/></a>
                    <a href="https://docs.blinkswap.xyz/" target="_blank"><img src={gitLogo} alt="" className="cursor-pointer size-5"/></a>
                </div>
            </div>
            <div className="w-full">
                <div className="font-variable text-white ml-auto w-fit">
                    © 2024 BLINK
                </div>
            </div>
        </div>
    );
}

export default FooterComponent;