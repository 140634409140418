import dashedLine from "../../assets/img/dashed_line.svg"
import holes from "../../assets/img/holes.svg"
import spotlight from "../../assets/img/spotlight.png"
import backer1 from "../../assets/img/backer1.svg"
import backer2 from "../../assets/img/backer2.svg"
import { AppContext } from "../../app/app.context"
import { useContext } from "react"
import MobileInfoComponent from "./MobileInfoComponent"

const infoText = [
    {
        title: "Auto-Rebasing",
        text: "Blast's L2 allows automatic ETH and USDB rebasing for individual accounts and smart contracts, simplifying integration and ensuring balance accuracy without administrative intervention."
    },
    {
        title: "L1 Staking Rewards",
        text: "After the Ethereum Shanghai upgrade, Blast now directly channels L1 ETH staking yields to L2 users, potentially leading to community-governed Blast-native solutions."
    },
    {
        title: "T-Bill Yield and Gas Revenue Sharing",
        text: "Blast distinguishes itself by delivering an auto-rebasing stablecoin, USDB, with returns from MakerDAO's T-Bills. Additionally, it shares gas cost income with Dapp developers, allowing them to increase earnings or lower user fees."
    }
]

function InfoComponent() {
    const context = useContext(AppContext)

    if(context.width < 840){
        return(
            <MobileInfoComponent/>
        )
    }

    return (
        <div className="w-full min-h-screen flex flex-col my-64 items-center">
            <div className="uppercase text-4xl text-white font-variable mb-32">
                Why Blast?
            </div>
            <div className="flex gap-10 w-[85%] z-20">
                {infoText.map((info)=>{
                    return(
                        <div className="flex flex-col text-white items-center w-full text-center font-variable text-xl">
                            <img src={dashedLine} alt="" className="mb-4 h-[66px]"/>
                            <div className="uppercase font-medium mb-10">
                                {info.title}
                            </div>
                            <div className="text-base">
                                {info.text}
                            </div>
                            <img src={dashedLine} alt="" className="mt-4 h-[66px]"/>
                        </div>
                        
                    )
                })}
            </div>
            <div className="relative w-full">
                <img src={holes} alt="" className="absolute translate-y-[-40%] opacity-5"/>
            </div>
            <div className="relative w-full z-10">
                <img src={spotlight} alt="" className="absolute translate-y-[-70%] translate-x-[-65%]"/>
            </div>
            <div className="relative w-full z-10">
                <img src={spotlight} alt="" className="absolute translate-y-[-70%] translate-x-[65%]"/>
            </div>
            {/* <div className="flex flex-col items-center mt-64 gap-24">
                <div className="uppercase text-4xl font-variable text-white">
                    Backed By
                </div>
                <div className="flex gap-32 items-center">
                    <img src={backer1} alt="" className="size-24"/>
                    <img src={backer2} alt="" className="size-20"/>
                </div>
            </div> */}
        </div>
    );
}

export default InfoComponent;