import Lottie from 'lottie-react';
import animationData from "../../assets/animation/holesData.json"

function AnimationHoleComponent() {
    return (
        <div className="w-full">
            <Lottie
                animationData={animationData}
            />
        </div>
    );
}

export default AnimationHoleComponent;