import { useContext } from "react";
import { AppContext } from "../../app/app.context";
import blinkLogo from "../../assets/img/blonk-logo.svg"
import HeaderMenuComponent from "./HeaderMenuComponent";

// const navbar = ["Features", "Blink Points", "Leaderboards"]

const navbar = [
    {
        name: "Features",
        action: ()=>{
            const el = document.getElementById("diagrama")
            el?.scrollIntoView({
                behavior: "smooth",
                block: 'nearest',
            })
        }
    },
    {
        name: "Blink Points",
        path: "https://app.blinkswap.xyz/quests"
    },
    {
        name: "Leaderboards",
        path: "https://app.blinkswap.xyz/leaderboard"
    }
]

function scrollToTop(){
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    })
}

function HeaderComponent() {
    const context = useContext(AppContext)

    if(context.width < 840){
        return (
            <HeaderMenuComponent/>
        );
    }

    return (
      <div className="flex items-center justify-between">
        <div className="flex gap-4 items-center z-30">
            <img src={blinkLogo} alt="" className="size-16 cursor-pointer" onClick={scrollToTop}/>
            <div className="font-mono font-light text-white text-3xl">Blink</div>
        </div>
        <div className="flex gap-16 text-grey items-center z-30">
            {navbar.map((menuName)=>{
                return(
                    <div className="cursor-pointer font-variable hover:text-white duration-150" onClick={menuName.action}>
                        {menuName.path?
                            <a href={menuName.path} target="_blank">{menuName.name}</a>
                            :
                            menuName.name
                        }
                    </div>
                )
            })}
        </div>
      </div>
    );
}

export default HeaderComponent;