import { useContext } from "react";
import diagrama from "../../assets/img/diagrama2.svg"
import spotlight from "../../assets/img/spotlight.png"
import { AppContext } from "../../app/app.context";
import MobileDiagramaComponent from "./MobileDiagramaComponent";

function DiagramaComponent() {
    const context = useContext(AppContext)

    if(context.width < 550){
        return(
            <MobileDiagramaComponent/>
        )
    }

    return (
        <div className="w-full flex justify-center" id="diagrama">
            <img src={spotlight} alt="" className="absolute translate-y-[-45%] opacity-70"/>
            <div className="flex flex-col">
                <img src={diagrama} alt="" className="z-20"/>
                <div className="relative">
                    <img src={spotlight} alt="" className="absolute translate-y-[-100%] translate-x-[80%] z-30 opacity-70"/>
                </div>
            </div>
        </div>
    );
}

export default DiagramaComponent;