import mobileDiagrama from "../../assets/img/mobile_diagrama.svg"
import mobileSpotlight from "../../assets/img/mobile_spotlight.png"

function MobileDiagramaComponent() {
    console.log("render diagrama")
    return (
        <div className="w-full flex justify-center" id="diagrama-mobile">
            <div className="flex flex-col items-center">
                <div className="relative w-[300%]">
                    <img src={mobileSpotlight} alt="" className="absolute z-30 w-full translate-y-[-30%] opacity-70"/>
                </div>
                <img src={mobileDiagrama} alt="" className="z-20"/>
            </div>
    </div>
    );
}

export default MobileDiagramaComponent;