import xLogo from "../../assets/img/x_logo.svg"
import dsLogo from "../../assets/img/ds_logo.svg"
import gitLogo from "../../assets/img/git_logo.svg"
import blinkLogo from "../../assets/img/blonk-logo.svg"

function scrollToTop(){
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    })
}

function MobileFooterComponent() {
    return (
        <div className="flex flex-col gap-10">
            <div className="w-full flex justify-center items-center z-30">
                <div className="w-[90%] z-20">
                    <div className="w-fit mr-auto" onClick={scrollToTop}>
                        <img src={blinkLogo} alt="" className="size-10 cursor-pointer"/>
                    </div>
                </div>
                <div className="absolute w-full flex gap-9 items-center justify-center z-30">
                    <div className="flex gap-6 items-center">
                        <a href="https://twitter.com/blinkswap" target="_blank"><img src={xLogo} alt="" className="cursor-pointer size-5"/></a>
                        <a href="https://discord.gg/blinkswap" target="_blank"><img src={dsLogo} alt="" className="cursor-pointer size-5"/></a>
                        <a href="https://docs.blinkswap.xyz/" target="_blank"><img src={gitLogo} alt="" className="cursor-pointer size-5"/></a>
                    </div>
                </div>
            </div>
            <div className="w-full flex justify-center">
                <div className="font-variable text-white w-fit text-sm">
                    © 2024 BLINK
                </div>
            </div>
        </div>
    );
}

export default MobileFooterComponent;