import React, { useEffect, useRef, useState } from 'react';
import HeaderComponent from './widgets/Header/HeaderComponent';
import HomePage from './pages/HomePage/HomePage';
import FooterComponent from './widgets/Footer/FooterComponent';
import { AppContext } from './app/app.context';
import { useResize } from './app/hooks/useResize';
import { cn } from './app/utils/cn';

function App() {
  const{ width } = useResize()

  const context = {
    width: width
  }

  return (
  <AppContext.Provider value={context} >
    <div className={cn("bg-black w-full min-h-screen px-24 py-12 overflow-hidden", {"px-5 py-5": width < 840})}>
      <HeaderComponent/>
      <HomePage/>
      <FooterComponent/>
    </div>
  </AppContext.Provider>
  );
}

export default App;
