import { useState } from "react";
import menuButton from "../../assets/img/menu_button.svg"
import closeMenuButton from "../../assets/img/close_menu_button.svg"
import blinkLogo from "../../assets/img/blonk-logo.svg"
import { cn } from "../../app/utils/cn";

// const navbar = ["Features", "Blink Points", "Leaderboards"]

const navbar = [
    {
        name: "Features",
        action: ()=>{
            const el = document.getElementById("diagrama-mobile")
            el?.scrollIntoView({
                behavior: "smooth",
                block: 'nearest',
            })
            console.log("anchor")
        }
    },
    {
        name: "Blink Points",
        path: "https://app.blinkswap.xyz/quests"
    },
    {
        name: "Leaderboards",
        path: "https://app.blinkswap.xyz/leaderboard"
    }
]

function scrollToTop(){
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    })
}

function HeaderMenuComponent() {
    const[isActive, setIsActive] = useState<boolean>(false)

    return (
        <div className="">
            <div className="flex items-center justify-between">
                <div className="flex gap-2 items-center z-[60]">
                    <img src={blinkLogo} alt="" className="size-10" onClick={scrollToTop}/>
                    <div className="font-mono font-light text-white text-xl">Blink</div>
                </div>
                <img src={isActive? closeMenuButton : menuButton} alt="" className="z-[60]" onClick={()=>{setIsActive(!isActive)}}/>
            </div>
            {isActive&&
                <div className="relative w-full">
                    <div className="w-full absolute border-2 border-grey mt-5 z-[60] bg-black flex flex-col gap-5 p-5">
                        {navbar.map((menuName)=>{
                            return(
                                <div className="bg-lightBlack p-3 font-light font-variable text-white text-lg" onClick={()=>{
                                    setIsActive(!isActive)
                                    menuName.action?.()
                                    }}>
                                    {menuName.path?
                                        <a href={menuName.path} target="_blank">{menuName.name}</a>
                                        :
                                        menuName.name
                                    }
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            {isActive&&
                <div className="fixed top-0 left-0 bg-black opacity-80 z-50 w-screen h-screen" onClick={()=>{setIsActive(!isActive)}}></div>
            }
        </div>
    );
}

export default HeaderMenuComponent;