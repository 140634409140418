import blinkText from "../../assets/img/blink-text.svg"
import xLogo from "../../assets/img/x_logo.svg"
import dsLogo from "../../assets/img/ds_logo.svg"
import gitLogo from "../../assets/img/git_logo.svg"
import spotlight from "../../assets/img/spotlight.png"
import { useContext } from "react"
import { AppContext } from "../../app/app.context"
import { cn } from "../../app/utils/cn"
import AnimationHoleComponent from "../Animation/AnimationHoleComponent"
import MobileLandComponent from "./MobileLandComponent"
import AnimationCircleComponent from "../Animation/AnimationCircleComponent"

function LandComponent() {
    const context = useContext(AppContext)

    if(context.width < 840){
        return(
            <MobileLandComponent/>
        )
    }

    return (
        <div className="flex flex-col">
            <div className="relative w-[200%]">
                <img src={spotlight} alt="" className="absolute z-20 translate-x-[60%] translate-y-[-30%]" />
            </div>
            <div className="relative w-[200%]">
                <div className="absolute z-20 translate-x-[5%] translate-y-[-30%]">
                    <AnimationCircleComponent/>
                </div>
            </div>
            <div className="flex w-full h-screen gap-10 justify-between mt-10 z-30">
                <AnimationHoleComponent/>
                <div className="w-full flex flex-col gap-10 mt-20">
                    <div className="w-auto">
                        <img src={blinkText} alt="" />
                    </div>
                    <div className={cn("text-grey font-variable text-2xl", {"text-lg": context.width < 1450})}>
                        Leading DEX on Blast. Providing <br/>advanced economy for liquidity pool<br/> providers, stakers and users.
                    </div>
                    <div className="flex justify-between gap-10 w-full">
                        <div className={cn("flex justify-between gap-4 w-2/3 font-variable text-2xl", {"text-lg": context.width < 1450})}>
                            <div className="w-full border-white border flex justify-center items-center uppercase py-4 text-white cursor-pointer hover:bg-white hover:text-black duration-200">
                                <a href="https://app.blinkswap.xyz/swap" target="_blank">Launch App</a>
                            </div>
                            <div className="w-full border-white border flex justify-center items-center uppercase py-4 text-white cursor-pointer hover:bg-white hover:text-black duration-200">
                                <a href="https://app.blinkswap.xyz/quests" target="_blank">Blink Points</a>
                            </div>
                        </div>
                        <div className="w-1/3 flex items-center justify-center">
                            <div className="flex gap-9 h-1/2">
                                <a href="https://twitter.com/blinkswap" target="_blank"><img src={xLogo} alt="" className="cursor-pointer"/></a>
                                <a href="https://discord.gg/blinkswap" target="_blank"><img src={dsLogo} alt="" className="cursor-pointer"/></a>
                                <a href="https://docs.blinkswap.xyz/" target="_blank"><img src={gitLogo} alt="" className="cursor-pointer"/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default LandComponent;