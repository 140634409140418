import dashedLine from "../../assets/img/dashed_line.svg"
import mobileHoles from "../../assets/img/mobile_holes.svg"
import mobileSpotlight from "../../assets/img/mobile_spotlight.png"
import backer1 from "../../assets/img/backer1.svg"
import backer2 from "../../assets/img/backer2.svg"

const infoText = [
    {
        title: "Auto-Rebasing",
        text: "Blast's L2 allows automatic ETH and USDB rebasing for individual accounts and smart contracts, simplifying integration and ensuring balance accuracy without administrative intervention."
    },
    {
        title: "L1 Staking Rewards",
        text: "After the Ethereum Shanghai upgrade, Blast now directly channels L1 ETH staking yields to L2 users, potentially leading to community-governed Blast-native solutions."
    },
    {
        title: "T-Bill Yield and Gas Revenue Sharing",
        text: "Blast distinguishes itself by delivering an auto-rebasing stablecoin, USDB, with returns from MakerDAO's T-Bills. Additionally, it shares gas cost income with Dapp developers, allowing them to increase earnings or lower user fees."
    }
]

function MobileInfoComponent() {

    return (
        <div className="w-full min-h-screen flex flex-col mt-20 items-center mb-32">
            <div className="relative w-[600%] z-10">
                <img src={mobileSpotlight} alt="" className="absolute translate-y-[-50%] translate-x-[-15%]"/>
            </div>
            <div className="uppercase text-xl text-white font-variable mb-5">
                Why Blast?
            </div>
            <img src={dashedLine} alt="" className="h-[66px]"/>
            <div className="flex flex-col items-center">
                {infoText.map((info)=>{
                    return(
                        <div className="flex flex-col text-white items-center w-full text-center font-variable text-base mt-5">
                            <div className="uppercase font-medium mb-5">
                                {info.title}
                            </div>
                            <div className="w-4/5 text-xs text-center font-extralight">
                                {info.text}
                            </div>
                            <img src={dashedLine} alt="" className="mt-4 h-[66px]"/>
                        </div>
                    )
                })}
            </div>
            <div className="relative w-[300%] z-10">
                <img src={mobileSpotlight} alt="" className="absolute translate-y-[-50%] translate-x-[40%]"/>
            </div>
            <div className="relative w-[300%] z-10">
                <img src={mobileSpotlight} alt="" className="absolute translate-y-[-40%] translate-x-[-35%] opacity-70"/>
            </div>
            <div className="relative w-[130%]">
                <img src={mobileHoles} alt="" className="absolute translate-y-[-25%] opacity-5"/>
            </div>
        </div>
    );
}

export default MobileInfoComponent;