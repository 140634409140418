import { createContext } from "react"

interface ContextInterface{
    width: number
}

export const AppContext = createContext<ContextInterface>(
    {
       width: 0,
    }
)